.h2-stl-conf{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;

    color: #000000;
}

.h3-stl-conf{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    
    color: #000000;
}
.h4-stl-conf{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;

    color: #000000;
}

.box-footer{
    border: 1px solid #FEBD18;
}