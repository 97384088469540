*{
    margin: 0;
    padding: 0;
}

/* .logoSE{
    background-image:url(../../images/banner.jpeg);
    padding-top: 3vh;
    padding-bottom: 4vh;
    height: 40vh;
} */

.imgLogoSE{
    justify-content: center;
    text-align: center;
    width: 100vw;
}

.footer-stl{
    background-color: #000000;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}

.footer-stl2{
    background-color: #000000;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
}


.footer-logo{
    display: flex;
    margin-left: auto;
    padding-right: 2em;
}

/* FORMULARIO */

.input-stl{
    padding-left: 1em;
    width: 356px;
    height: 47px;
}

.iconwidth{
    width: 25px;
    height: 25px;
}

/*Media queries*/
@media (max-width: 800px) {
    .imgLogoSE{
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}