.popupBox{
    width: 40vw !important;
    border-radius: 20px !important;
}
.popupBox2{
    width: 80vw !important;
    border-radius: 20px !important;
}
.imgBox{
    position: absolute;
    width: 14%;
    height: 200px;

    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 36px;
}
.imgNumber{
    position: absolute;
    left: -7px;
    top: -7px;
    width: 38px;
    height: 38px;
    background: #FEBD18;
    border-radius: 20px;
}

.aPopUp{
    color:#FEBD18;
}
.aPopUp:hover{
    color:#000;
}

@media (max-width: 1500px) {
    .popupBox{
        width: 60vw !important;
    }
    .popupBox2{
        width: 90vw !important;
    }
    
}
@media (max-width: 1100px) {
    .popupBox{
        width: 80vw !important;
    }
    .popupBox2{
        width: 90vw !important;
    }
    
}
@media (max-width: 850px) {
    .popupBox{
        width: 90vw !important;
    }
    .popupBox2{
        width: 90vw !important;
    }
    .imgBox{
        position: absolute;
        width:40%;
        height: 200px;
        margin-left: 30px !important;
    
        background: #FFFFFF;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
    }
    
}
@media (max-width: 450px) {
    .popupBox{
        width: 90vw !important;
        text-align: center;
    }
    .popupBox2{
        width: 90vw !important;
        text-align: center;
    }
    .marText{
        margin-left: 20px;
        margin-right: -10px;
    }
    .imgBox{
        margin-left: 20px !important;

    }
}