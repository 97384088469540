*{
    margin: 0;
    padding: 0;
}

/* FONTS */
.h2-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-size: 40px;
    line-height: 52px;
}

.h3-stl-footer{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
}
.h3-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-size: 28px;
    line-height: 27px;
    text-align: center;
}
.h4-stl-footer{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
}
.h4-stl-footer-pad{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    padding-left: 100px;
}


.h4-stl{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
}

.h5-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
}
.h6-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.h6-stl-1{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
}
 
/* COLORS */

.black-color{
    color: #000000;
}
.white-color{
    color: #FFFFFF;
}
.gray-color{
    color:#818181;
}
/*IMG*/
.img-width{
    width: auto;
}


/* HEADER */

.mainHeader{
    background-color:#000000;
    margin: 0%;
    max-width: 100%;
}
.logoMainHeader{
    padding-left: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
}
.Center{
    text-align: center;
}
.Left{
    text-align: left;
}
.Left2{
    text-align: left;
}

.Rigth{
    text-align: right;
}

a{
    color: #000
}
a:hover {
    border-bottom: 1px solid;
    color: #000;
}

.flex-stl{
    display: flex;
}
.flex-left{
    display: flex;
    margin-left: auto;
    margin-right: 26%;
    margin-top: -50px;
}
/*Borde*/
.yellow-border{
    border-right: 3px solid #FEBD18;
}

.logoFooter{
    display: flex;
    margin-left: auto;
}

.pad-l5{
    padding-left: 3em;
}
.pad-l6{
    display: flex;
    justify-content: center !important;
}

.pad-alert{
    padding-top: 0;
    padding-left: 3em;
}
.EmptySpace1{
    padding-top: 2em;
    padding-bottom: 2em;
}

.noGarantyShadow{
    -webkit-box-shadow: 1px 42px 15px -2px rgba(0,0,0,0.45); 
    box-shadow: 1px 42px 15px -2px rgba(0,0,0,0.45);
}
@media (max-width: 1600px) {
    .flex-left{
        margin-right: 16%;
    }
    .logoFooter{
        padding-left: 60%;
    }
    .textFooter{
        padding-left: 0%;
    }
}
/*Media Query*/
@media (max-width: 1300px) {
    .h4-stl-footer-pad{
        padding-left: 50px;
    }
    .flex-left{

        margin-right: 18%;
        margin-top: -50px;
    }
    .pad-alert{
        padding-top: 0;
        padding-left: 200px;
    }
}
@media (max-width: 1100px) {
    .h3-stl-footer{
        font-size: 22px;
    }
    .h4-stl-footer{
        font-size: 18px;
    }
    .h4-stl-footer-pad{
        font-size: 18px;
        padding-left: 70px;
    }
    .flex-left{
        margin-right: 12%;
        margin-top: -50px;
    }
    .logoFooter{
        padding-left: 23%;
    }
    .pad-alert{
        padding-top: 0;
        padding-left: 200px;
    }
}
@media (max-width: 800px) {
    .h4-stl-footer-pad{
        padding-left: 30px;
    }
    .flex-left{
        margin-right: 8%;
        margin-top: -50px;
    }
    .logoFooter{
        padding-left: 250%;
    }
    .pad-alert{
        padding-top: 0;
        padding-left: 220px;
    }
}
@media (max-width: 450px) {
/*IMG*/
.img-width{
    width: 100%;
}
.flex-left{
    display: flex;
    margin-left: auto;
    margin-right: 30%;
    margin-top: -50px;
}
.h4-stl-footer{
    text-align: center;
}
.h4-stl-footer-pad{
    text-align: center;
    padding-left: 0;
}
.logoFooter{
    padding-left: 20%;
    margin: 0;
}
.logoMainHeader{
    padding-left: 8em;

}
.pad-l5{
    padding-left: 0.7em;
}
.pad-alert{
    padding-top: 1em;
    padding-left: 2em;
}
}