.text-nav-stl{
    color: #FFFFFF;
}
.text-nav-stl{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
}

/* a:link{
    color:#FFFFFF !important;
} */
.text-nav-stl:hover{
    color: #FFBE17;
    border-bottom: 2px solid #FFBE17;
    padding-bottom: 10px;
}


@media (max-width: 450px) {

    .nav{
        flex-direction: column;
    }
}