.Background-yellow{
    background-image:url(../../images/Background-yellow.png);
    padding-top: 3vh;
    padding-bottom: 4vh; /*O usar em*/
}
.background-yellow2{
    background-image:url(../../images/Footer-solidez.png);
}

/*Links*/

.Terms{
    color: white;
}
.Terms:link{
    color: white;
}
.Terms:hover{
    color:#FEBD17
}

.linkText{
    color: #000;
    text-decoration: underline;
    text-decoration-color:#FEBD17 ;
}
.linkText:hover{
    color: #FEBD17;
    
}
/* BACKGROUND */

.background-gray{
    background: #F2F2F2;
}
.background-black{
    background: linear-gradient(0deg, #2C2C2C, #2C2C2C), #FEBD17;
}

.imgFooterSolid{
    width: 20%;
}

@media (max-width: 450px) {
    .imgFooterSolid{
        width: 90%;
        padding-left: 0%;
    }
}