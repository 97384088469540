.container-stl{
    margin-left:15%;
}

@media (max-width: 1600px) {
    .container-stl{
        margin-left:0;
        padding-left: 5%;
        padding-right: 10%;
    }
}
@media (max-width: 800px) {
    .container-stl{
        text-align: center;
        margin-left:0;
        padding-left: 10%;
        padding-right: 0%;
    }
}
@media (max-width: 450px) {
    .container-stl{
        text-align: center;
        margin-left:0;
        padding-left: 10%;
        padding-right: 10%;
    }
}