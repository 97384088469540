/* FORMULARIO */

.input-stl{
    padding-left: 1em;
    width: 378px;
    height: 40px;
}
.inputBig-stl{
    padding-left: 1em;
    width: 378px;
    height: 106px;
    white-space: initial;
}


.iconwidth{
    width: 25px;
    height: 25px;
}