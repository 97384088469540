.alert-stl{
    width: 400px;
    height : 40px !important;
    margin-top: 0px;
} 
@media (max-width: 1100px) {
    .alert-stl{
        width: 280px;
        height : 50px !important;
        text-align: center;
    } 
    }
@media (max-width: 800px) {
    .alert-stl{
        width: 250px;
        height : 50px !important;
        text-align: center;
    } 
    }
@media (max-width: 450px) {
    .alert-stl{
        width: 305px;
        height : 50px !important;
        text-align: center;
    } 
    }
