.h4-stl-form-g{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;

    color: #818181;
}

.input-stl-g{
    background: #EBEBEB;
    width: 60vw;
    height:70px;
    border:1px solid#C4C4C4;
    border-right:0 ;
    padding-left: 50px;
}

.divbutton-stl-g{
    background: #EBEBEB;
    border:1px solid#C4C4C4;
    border-left:0 ;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

button:disabled{
    background-color:#818181 !important;
}