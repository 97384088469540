.dhl-box{
    background: #EBEBEB;
    width: 40vw;
    height: 70px;
    padding: 25px 20px;
}

.state-box-track{
    background: #FFFEFE;
    width: 40vw;
    padding: 25px 20px;
    border: 1px solid #EBEBEB;
}

.border-text{
    border-bottom: 2px solid #EBEBEB;
}


.button-traking-state{
    background-image:url('../../images/Arrow_button.png') ;
    width: 20px;
    height: 20px;
    border:0;
    margin-left: auto;   
}
.button-traking-stateup{
    background-image:url('../../images/Arrow_top.png') ;
    width: 20px;
    height: 20px;
    border:0;
    margin-left: auto;   
}
@media (max-width: 450px) {
    .dhl-box{
        width: 90%;
    }
    .state-box-track{
        width: 90%;
    }
}