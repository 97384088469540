.Tabs-stl{
    width: 98%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
}

.tabs-only-stl{
    width: 100%;
}
/*TABS*/
.tab-stl{
    padding-left: 2em;
    padding-top: 2em;
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}
.MuiTab-root{
    max-width:17% !important;
}

@media (max-width: 1500px) {
    .Tabs-stl{
        width: 95%;
        justify-content: space-evenly;
        flex-direction: row;
    }
}
@media (max-width: 1100px) {
    .MuiTab-root{
        max-width:17% !important;
        min-width:30% !important;
    }
}
@media (max-width: 450px) {
    .Tabs-stl{
        width: 90%;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .tabs-only-stl{
        width: 50%;
    }
    .MuiTab-root{
        max-width:17% !important;
        min-width:50% !important;
    }
}
