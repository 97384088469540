.button-pos-r{
    text-align: right;
}
.button-pos-l{
    text-align: left;
}
/* .dzu-previewStatusContainer{
    width: 100% !important;
}

progress{
    width: 0%;
}
.dzu-previewButton{
    width: 100px !important;
} */
.dzu-inputLabel{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #838181;
}

.ply-5{
    margin-left: 3em;
}

@media (max-width: 450px) {
    .button-pos-r{
        text-align: center;
    }
    .button-pos-l{
        text-align: center;
    }
    .ply-5{
        margin-left: 0;
    }
}