.background-gray{
    background: #EBEBEB;
}
.info-box{
    width: 50%;
    height: 294px;
}

.info-text{
    font-family: IBM Plex Sans Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}
.mapa-box{
    width: 70%;
    height: 304px;
}

@media (max-width: 450px) {
    .info-box{
        width: 95%;
    }
    .mapa-box{
        width: 95%;
  
    }
}
