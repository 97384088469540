
.box-product{
    margin-left: 2em;
    width: 80%;
    height: 12em;
    border-radius: 10px;
    border: 1px solid #FEBD18;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.imgProduct{
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 5px;
}

@media (max-width: 1500px) {

    .box-product{
        margin-left: 0;
        width: 100%;
        height: 10em;
    }
    
}
@media (max-width: 1100px) {

    .box-product{
        width: 100%;
        height: 8em;
    }

    
}
@media (max-width: 800px) {
    .box-product{
        margin-left: -1em;
        padding-left: 0;
        width: 150px;
        height: 9em;
    }
    
}
@media (max-width: 450px) {

    .box-product{
        margin-left: 6.5em;
        width: 50%;
        height: 12em;
    }

}




