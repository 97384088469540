.dropdown-stl{
    background-color: #000;
    color: white;
    border-top: 4px solid #FEBD18 ;
}
.borde{
    border-top: 4px solid #FEBD18 ;
    display: inline-block;
    width: 300px;
}
.ui.menu{
    border:0;
    border-radius: 0;
}
.ui.compact.menu{
    border-radius: 0;
    border-top: 4px solid #FEBD18 !important;
}
.ui.compact.menu .item:last-child {
    border-radius: 0;
}
i.large.icon, i.large.icons{
    border-top:4px solid #FEBD18 !important;
    padding-top: 10px !important;
}

.bOfIKH{
    color:#FEBD18 !important; 
}
.hYUoBw{
    color:#FEBD18 !important; 
}
.GQamn{
    color:#FEBD18 !important; 
}
