.Boton-main{
    background: #FEBD18;
    color: #000;
    height: 73px;
    width: 415px;
    padding: 0 30px;
    font-family: 'IBM Plex Sans Condensed';
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align : center;
}
.Boton-warranty{
    background: #FEBD18 !important;
    color: #000;
    height: 40px;
    width: 166px;
    padding: 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
}

.Boton-warranty:disabled{
    background: #D3D3D3  !important;
    color: #818181;
    height: 40px;
    width: 166px;
    padding: 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
}
.h3-stl-button{
font-family: IBM Plex Sans Condensed;
padding-top: 10px;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 17px;
text-align: center;
color: #000000;

}
.h3-stl-button2{
font-family: IBM Plex Sans Condensed;
font-style: normal;
font-weight: bold;
padding-top: 5px;
font-size: 11px;
line-height: 17px;
text-align: center;
color: #000000;

}

.boton-Second{
    background: #FEBD18;
    border: 0;
    border-radius: 3;
    color: '#000';
    height: 73px;
    width: 415px;
    padding: '0 30px';
}

@media (max-width: 1500px) {
.h3-stl-button{
    font-size: 16px;
}
}
@media (max-width: 1100px) {
.h3-stl-button{
    font-size: 14px;
}
.boton-Second{
    width: 220px;
}
.Boton-main{

    height: 73px;
    width: 300px;
}
}
@media (max-width: 800px) {
.h3-stl-button{
    font-size: 12px;
}
.Boton-main{
    width: 250px;
}
.boton-Second{
    width: 150px;
    height: 35px;
}
}
@media (max-width: 500px) {
.h3-stl-button{
    font-size: 14px;
}
.Boton-main{
    width: 270px;
}
.boton-Second{
    width: 240px;
    height: 28px;
    
}
}